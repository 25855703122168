.App {
  text-align: center;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bg_logo {
  position: relative;
  background: url('./assets/quizza_bg.png') no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  /* margin-top: 24px; */
  /* margin-bottom: -50px; */
}



/* Spinner */
@keyframes ldio-rpinwye8j0b {
  0% { transform: rotate(0deg) }
  50% { transform: rotate(180deg) }
  100% { transform: rotate(360deg) }
}

.loader{
  border: 2px solid #FFFFFF; 
  border-top-color: #14B48C; 
  width: 40px; 
  height: 40px; 
  border-radius: 50%; 
  /* animation: spin 1s infinite; */
  animation: ldio-rpinwye8j0b 1s linear infinite;;
}

@keyframes spin{
  from{transform: rotate(0deg);}
  to{transform: rotate(360deg);}
}

.logo {
  width: 6rem;
}

/* .logo:hover {
  width: 8rem;
} */

.navbar_text_color {
  color: #DDD;
}

.navbar_text_color:hover {
  color: #FFFFFF;
}

.text_color {
  color: #999;
}

.text_color_warning {
  color: #debf88;
}

.btn_bg_color {
  background-color: #debf88;
}

.btn_bg_color:hover {
  background-color: #af966c;
}

.btn_bg_color1 {
  background-color: #ea2d34;
}

.btn_bg_color1:hover {
  background-color: #be272c;
}


/* Floating Label Input */
#float-label {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  position: relative;
}

#float-label input {
  width: 100%;
  height: 60px;
  padding: 14px 16px 0 10px;
  outline: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

#float-label label {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 0 12px;
  color: #999;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 26px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
}

#float-label:focus-within label {
  transform: translate(0, 8px) scale(0.75);
}

#float-label .Active {
  transform: translate(0, 12px) scale(0.75);
}
